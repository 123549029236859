<template>
  <div>
    <div class="right-menu shipping-right">

      <subHeader pageName="CATEGORIES" />

      <tutorialsAction />

      <div class="db_top">
        <div class="db-container">
          
          <moduleStats/>

          <!-- search block starts here -->
          <moduleSearch :moduleName="'category'" :disable="tourIsActive" ref="module_search"/>
          <!-- search block starts here -->

          <!-- data table starts here -->
          <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
            <div class="tableScrollWrap" ref="categoriesTable">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div class="ctmCheckWrap">
                        <label class="ctmTableCheckContainer">
                          <input 
                            type="checkbox" 
                            @click="toggleIsCheckedAll()"
                            :checked="checkedItems.length != 0 && checkedItems.length == categoryList.length"
                            :disabled="tourIsActive || this.Previewform.id != ''"
                          >
                          <span class="checkmark"></span>
                        </label>
                        <div class="ctmTableImgWrap">
                          <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                        </div>
                      </div>
                    </th>
                    <th class="ctmPr-3">
                      <div class="ctmTableTitleWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                      </div>
                    </th>
                    <th class="ctmPr-3">
                      <div class="ctmTableTitleWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Type">Type</p>
                      </div>
                    </th>
                    <th class="ctmPr-3">
                      <div class="ctmTableTitleWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Parent Category">Parent Category</p>
                      </div>
                    </th>
                    <th class="ctmPr-3">
                      <div class="ctmTableTitleWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Active/Disable">Active/Disable</p>
                      </div>
                    </th>
                    <th class="ctmPr-3">
                      <div class="ctmTableTitleWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Live Preview">Live Preview</p>
                      </div>
                    </th>
                    <th class="ctmPr-3">
                      <div class="ctmTableTitleWrap">
                        <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="isLoadingBox">
                  <tr v-for="i in 5" :key="i">
                    <td>
                      <div class="ctmCheckWrap">
                        <vue-skeleton-loader
                          type="rect"
                          :width="20"
                          :height="20"
                          animation="fade"
                        />
                        <div class="ctmDataTableImgBox">
                          <vue-skeleton-loader
                            type="circle"
                            :width="36"
                            :height="36"
                            animation="fade"
                          />
                        </div> 
                      </div>
                    </td>
                    <td class="mainTableTitle">
                      <div class="editFieldTitle">
                        <vue-skeleton-loader
                          type="rect"
                          :width="200"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="tableBodyCtmTxt">
                        <vue-skeleton-loader
                          type="rect"
                          :width="250"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </td>
                    <td style="position: relative;">
                      <vue-skeleton-loader
                        type="rect"
                        :width="95"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <vue-skeleton-loader
                        type="rect"
                        :width="175"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <vue-skeleton-loader
                        type="rect"
                        :width="102"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <vue-skeleton-loader
                        type="rect"
                        :width="102"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>

                  <tr v-if="categoryList && categoryList.length == 0">
                    <td colspan="7" style="text-align:center" v-if="categorySearchKeyword != ''">
                      <p class="mb-0">
                        {{ categorySearchKeyword ? `No categories found matching '${categorySearchKeyword}'` : 'No Categories Available' }}
                      </p>
                    </td>
                  </tr>
                  
                  <tr v-for="(category, index) in categoryList" :key="index">

                    <!-- checkbox & image block starts here -->
                    <td @click.prevent="previewCategory(category,index,'input',$event)">
                      <div class="ctmCheckWrap" data-open="true">
                        <label class="ctmTableCheckContainer" data-open="false" @click.prevent=" category.id != -1 ? checkedItemHandle(category.id) : ''">
                          <input type="checkbox" v-model="checkedItems" :value="category.id" :disabled="tourIsActive || category.id == -1" data-open="false">
                          <span class="checkmark" data-open="false"></span>
                        </label>
                        <!-- for saved category -->
                        <div class="ctmDataTableImgBox" data-open="true" v-if="category.id != -1">
                          <img 
                            :src="category.image ? category.image_src : '/images/no_image.png'" 
                            alt="" 
                            v-if="category.id == -1" 
                            data-open="true"
                            @error="handleImageError"
                          >
                          <img 
                            :src="generateUrl(category) ? generateUrl(category) : '/images/no_image.png'" 
                            alt="" 
                            data-open="true"
                            @error="handleImageError" 
                            v-else
                          >
                        </div>
                        <!-- for unsaved category -->
                        <div class="ctmDataTableImgBox" data-open="true" v-else>
                          <img 
                            :src="category.addImage ? category.addImage_src : '/images/no_image.png'" 
                            alt="" 
                            v-if="category.id == -1" 
                            @error="handleImageError"
                            data-open="true"
                          >
                          <img 
                            :src="generateUrl(category) ? generateUrl(category) : '/images/no_image.png'" 
                            alt="" 
                            data-open="true"
                            @error="handleImageError"
                            v-else
                          >
                        </div>
                      </div>
                    </td>
                    <!-- checkbox & image block ends here -->

                    <!-- category name block starts here -->
                    <td class="mainTableTitle" @click.prevent="previewCategory(category,index,'input',$event)">
                      <div class="editFieldTitle" data-open="true" id="category-name-field-3">
                        <input 
                          type="text" 
                          placeholder="Write a category name"
                          v-model="category.name"
                          @focus="categorySelection(category)" 
                          @blur="handleOTGUpdate(category,index)" 
                          :disabled="tourIsActive"
                          data-open="false"
                        >
                      </div>
                    </td>
                    <!-- category name block ends here -->

                    <!-- category type block starts here -->
                    <td @click.prevent="previewCategory(category,index,'solid',$event)">
                      <div class="tableBodyCtmTxt" data-open="true">
                        <p data-open="true">
                          {{ category.id != Previewform.id ? category.parent_id == null ? 'Main' : 'Sub' : selectedCategoryType == 'main' ? 'Main' : 'Sub' }}
                        </p>
                      </div>
                    </td>
                    <!-- category type block ends here -->

                    <!-- parent category block starts here -->
                    <td style="position: relative;" @click.prevent="previewCategory(category,index,'solid',$event)">
                      <div class="selectCategoryBoxTable" data-open="true">
                        <p data-open="true" v-if="category.id == -1">
                          {{
                            selectedCategoryType == 'sub' ? category.parent_id ? 
                            category.parent_id.name.length > 30 ? category.parent_id.name.substr(0, 30) + "..." : 
                            category.parent_id.name : '--' : category.parents ? category.parents.length == 0 ? '--' :
                            category.parents[0].name.length > 30 ? category.parents[0].name.substr(0, 30) + "..." : 
                            category.parents[0].name : '--'
                          }}
                        </p>
                        <p data-open="true" v-else-if="(category.id != -1) && (category.id == Previewform.id)">
                          {{
                            selectedCategoryType == 'sub' ? category.parent_id ?
                            category.parent_id.name.length > 30 ? category.parent_id.name.substr(0, 30) + "..." :
                            category.parent_id.name : '--' : '--'
                          }}
                        </p>
                        <p data-open="true" v-else>
                          {{
                            category.parents ? category.parents.length == 0 ? '--' :
                            category.parents[0].name.length > 30 ? category.parents[0].name.substr(0, 30) + "..." :
                            category.parents[0].name : '--'
                          }}
                        </p>
                      </div>
                    </td>
                    <!-- parent category block ends here -->

                    <!-- active/disabled block starts here-->
                    <td @click.prevent="previewCategory(category,index,'input',$event)">
                      <div class="actionSwitch" data-open="true">
                        <label class="switch" data-open="false">
                          <input
                            type="checkbox"
                            :id="`customSwitch-p-${category.id}`"
                            :value="category.isActive"
                            v-model="category.isActive"
                            :disabled="tourIsActive"
                            data-open="false"
                          >
                          <span class="slider" data-open="false" @click.stop="updateCategoryStatus(category,index)"></span>
                        </label>
                      </div>
                    </td>
                    <!-- active/disabled block ends here-->

                    <!-- preview block starts here -->
                    <td @click.prevent="previewCategory(category,index,'input',$event)">
                      <div class="ctmDataTableActionBtn" data-open="true">
                        <button 
                          class="previewFunction" 
                          @click.prevent="livePreview(category)" 
                          :disabled="tourIsActive && category.id == -1" 
                          data-open="false"
                        >
                          <el-tooltip class="box-item" effect="dark" content="Click to preview" placement="right">
                            <i class="fa fa-external-link-square" data-open="false"></i>
                          </el-tooltip>
                        </button>
                      </div>
                    </td>
                    <!-- preview block ends here -->

                    <!-- action block starts here -->
                    <td @click.prevent="previewCategory(category,index,'input',$event)">
                      <div class="ctmDataTableActionBtn" v-if="isDeletingItem(category.id) && deleteLoader" data-open="false">
                        <loaderBtn />
                      </div>
                      <div class="ctmDataTableActionBtn" data-open="true" v-else>
                        <button class="delFunction" @click.prevent="deleteCategory(category,index)" :disabled="tourIsActive" data-open="false">
                          <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                        </button>
                      </div>
                    </td>
                    <!-- action block ends here -->

                  </tr>

                  <!-- load more block starts here -->
                  <tr v-if="isPending">
                    <td>
                      <div class="ctmCheckWrap">
                        <vue-skeleton-loader
                          type="rect"
                          :width="17"
                          :height="20"
                          animation="fade"
                        />
                        <div class="ctmDataTableImgBox">
                          <vue-skeleton-loader
                            type="circle"
                            :width="36"
                            :height="36"
                            animation="fade"
                          />
                        </div> 
                      </div>
                    </td>
                    <td class="mainTableTitle">
                      <div class="editFieldTitle">
                        <vue-skeleton-loader
                          type="rect"
                          :width="200"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="tableBodyCtmTxt">
                        <vue-skeleton-loader
                          type="rect"
                          :width="250"
                          :height="20"
                          animation="fade"
                        />
                      </div>
                    </td>
                    <td style="position: relative;">
                      <vue-skeleton-loader
                        type="rect"
                        :width="95"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <vue-skeleton-loader
                        type="rect"
                        :width="175"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <vue-skeleton-loader
                        type="rect"
                        :width="80"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                    <td>
                      <vue-skeleton-loader
                        type="rect"
                        :width="80"
                        :height="20"
                        animation="fade"
                      />
                    </td>
                  </tr>
                  <!-- load more block ends here -->
                  
                </tbody>
              </table>
            </div>

            <!-- add/update category form starts here -->
            <div class="productSlideBox" ref="viewInputForm" :class="Previewform.id ? 'activeRow' : '' ">
              <div class="tableActionBtns" id="category-details-preview">
                <div class="productTableSideBar">
                  <div class="row">
                    <div class="col-md-8" id="category-name-field-2">
                      <div class="viewOrderTitle" v-if="Previewform.created_by_user == null">
                        <input 
                          type="text" 
                          placeholder="Write a category name" 
                          :value="`${Previewform.name}`+' (Default Category)'" 
                          disabled
                        >
                      </div>
                      <div class="viewOrderTitle" v-else>
                        <input 
                          type="text" 
                          placeholder="Write a category name" 
                          v-model="Previewform.name" 
                          @input="slugGeneration('name')"
                          :disabled="tourIsActive"
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="orderViewNav">
                        <ul>
                          <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                            <li class="sm-extand-hide">
                              <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                  <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                    <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                    <path d="m16.5.522-6 5.907"/>
                                    <path d="m11 16.521 5.5.002-.013-5.5"/>
                                    <path d="m16.5 16.429-6-5.907"/>
                                    <path d="m.5 5.5v-5h5.5"/>
                                    <path d="m6.5 6.429-6-5.907"/>
                                    <path d="m6 16.516-5.5.007v-5.023"/>
                                    <path d="m6.5 10.5-6 6"/>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                            <li>
                              <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                  <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                    <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                    <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                      <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                      <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                  </g>
                                </svg>
                              </button>
                            </li>
                          </el-tooltip>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sectionTabsLink">
                  <ul onclick="window.myFunction(event)">
                    <li v-if="!tourIsActive">
                      <a class="active" id="category-detail-navbtn" href="#category-info">Category Info</a>
                    </li>
                    <li v-if="!tourIsActive">
                      <a href="#seo-essentials" id="category-seo-navbtn">SEO Essentials</a>
                    </li>
                  </ul>
                  <div class="updateBtnBox" v-if="isLoading">
                    <loaderBtn/>
                  </div>
                  <div class="updateBtnBox" id="save-category-btn" v-else>
                    <button 
                      form="category-information" 
                      type="submit" 
                      :disabled="tourIsActive"
                    >
                      {{ Previewform.id == -1 ? 'Save' : 'Update' }}
                    </button>
                  </div>
                </div>
              </div>
              <ValidationObserver ref="categoryFormObserver">
                <div class="productTableInfoBlock" id="category-info">
                  <div class="productTableInfoWhiteBox">
                    <form id="category-information" @submit.prevent="validate()">
                      <div class="tableFromFieldBox">
                        <label for="category-name-field-1">Name<span>*</span></label>
                        <ValidationProvider name="name" rules="required|max:250" v-slot="{ errors }">
                          <div class="tableFromFieldItem">
                            <input 
                              type="text" 
                              name="category-name-field-1" 
                              id="category-name-field-1" 
                              placeholder="Name" 
                              v-model="Previewform.name" 
                              @input="slugGeneration('name')" 
                              :disabled="!Previewform.created_by_user || tourIsActive" 
                            >
                            <p class="fieldSeoMessage" v-if="Previewform.name && Previewform.name.length <= 50">
                              <span style="display: inline-flex;margin-right: 5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                  <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                </svg> 
                              </span>
                              Do not exceed 50 characters when entering the category name for SEO
                            </p>
                            <p class="fieldSeoMessage" v-else-if="Previewform.name && Previewform.name.length > 50"> Not SEO friendly</p>
                            <p class="remainingTxt" v-if="Previewform.name">
                              <span>
                                {{Previewform.name ? Previewform.name.length <= 50 ? 50 - Previewform.name.length : 0 : 50 }}
                              </span> Remaining
                            </p>
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="tableFromFieldBox">
                        <label for="category-slug-field">Slug<span>*</span></label>
                        <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                          <div class="tableFromFieldItem">
                            <input 
                              type="text" 
                              name="category-slug-field" 
                              id="category-slug-field" 
                              placeholder="Slug"
                              v-model="Previewform.slug" 
                              @blur="slugGeneration('slug')" 
                              :disabled="!Previewform.created_by_user || tourIsActive"
                            >
                            <p class="fieldSeoMessage" v-if="Previewform.slug && Previewform.slug.length <= 60">
                              <span style="display: inline-flex;margin-right: 5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                  <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                </svg> 
                              </span>
                              Do not exceed 60 characters when entering the category slug for SEO
                            </p>
                            <p class="fieldSeoMessage" v-else-if="Previewform.slug && Previewform.slug.length > 60"> Not SEO friendly</p>
                            <p class="remainingTxt" v-if="Previewform.slug">
                              <span>{{Previewform.slug ? Previewform.slug.length <= 60 ? 60 - Previewform.slug.length : 0 : 60 }}</span> 
                              Remaining
                            </p>
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="tableFromFieldBox">
                        <label for="category-type-field">Category Type<span>*</span></label>
                        <ValidationProvider name="category type" rules="required" v-slot="{ errors }">
                          <div class="vueCtmSelectBlock">
                            <select 
                              name="category-type-field" 
                              id="category-type-field" 
                              v-model="selectedCategoryType" 
                              @change="categoryTypeChangeHandle"
                              :disabled="!Previewform.created_by_user || tourIsActive"
                            >
                              <option value="main">Main</option>
                              <option value="sub">Sub</option>
                            </select>
                          </div>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="tableFromFieldBox" v-if="selectedCategoryType == 'sub'" id="parent-category-section">
                        <label for="parent-category-field">Parent Category<span>*</span></label>
                        <div class="vueSelectBlock">
                          <ValidationProvider name="parent category" :rules="selectedCategoryType == 'sub' ? 'required' : '' " v-slot="{ errors }">
                            <Multiselect
                              ref="parentSelection"
                              id="parent-category-field"
                              v-model="Previewform.parent_id"
                              :options="parentCategory"
                              placeholder="Search and select a parent category"
                              :custom-label="customProductCategoryName"
                              label="name"
                              track-by="id"
                              :allow-empty="false"
                              deselect-label="Can't remove the selected item"
                              :searchable="true"
                              @search-change="loadParentCategories"
                              :loading="parentCategoriesLoader"
                              :disabled="tourIsActive"
                            >
                              <span slot="noResult">
                                No category found matching the searched keyword.
                              </span>
                            </Multiselect>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="tableFromFieldBox" id="category-description-field" v-if="Previewform.id != ''">
                        <label for="sDescription">Description<span>*</span></label>
                        <ValidationProvider name="description" rules="required|max:5000" v-slot="{ errors }">
                          <div class="quillDetailTxtBox">
                            <quill-editor  
                              name="sDescription"
                              :options="editorOptions"
                              v-model="Previewform.description"
                              :disabled="tourIsActive"
                            >
                            </quill-editor>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="tableFromFieldBox" v-if="Previewform.id == -1">
                        <label>Featured Image<span>*</span></label>
                        <div class="imgUploaderTxt">
                          <input 
                            type="file" 
                            ref="addPreviewFileInput" 
                            @change.prevent="changeImage" 
                            id="feature_img" 
                            accept="image/png,image/jpg,image/jpeg,image/webp" 
                            hidden 
                            :disabled="tourIsActive"
                          >
                          <label for="feature_img">
                            <div class="category-tab-img" id="category-featured-image">
                              <img 
                                :src="Previewform.addImage ? 
                                Previewform.addImage_src ? 
                                Previewform.addImage_src : base_image_url + setting.store_id + '/category/' + Previewform.addImage : '/images/no_image.png' " 
                                alt=""
                                @error="handleImageError"
                              />
                            </div>
                            <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                          </label>
                        </div>
                      </div>
                      <div class="tableFromFieldBox" id="category-featured-image-update" v-else>
                        <label>Featured Image<span>*</span></label>
                        <div class="imgUploaderTxt">
                          <input 
                            type="file" 
                            ref="previewFileInput" 
                            @change.prevent="changeImagePreviewForm" 
                            id="feature_img1" 
                            accept="image/png,image/jpg,image/jpeg,image/webp" 
                            hidden 
                            :disabled="tourIsActive"
                          >
                          <label for="feature_img1">
                            <div class="category-tab-img">
                              <img 
                                :src="Previewform.image ? Previewform.image_src ? Previewform.image_src : base_image_url + setting.store_id + '/category/' + Previewform.image : '/images/no_image.png' " 
                                alt=""
                                @error="handleImageError"
                              />
                            </div>
                            <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="productTableInfoWhiteBox" id="seo-essentials">
                    <div class="productTableInfoWhiteBoxTitle">
                      <h5>SEO</h5>
                    </div>
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="meta title" rules="max:250" v-slot="{ errors }">
                        <label for="category-meta-title">Meta Title</label>
                        <div class="tableFromFieldItem">
                          <input 
                            type="text" 
                            name="category-meta-title" 
                            id="category-meta-title" 
                            placeholder="Meta title" 
                            form="category-information"
                            v-model="Previewform.meta_title"
                            :disabled="tourIsActive"
                          >
                          <p class="fieldSeoMessage" v-if="Previewform.meta_title && Previewform.meta_title.length <= 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 60 characters when entering the category meta title for SEO
                          </p>
                          <p class="fieldSeoMessage" v-else-if="Previewform.meta_title && Previewform.meta_title.length > 60">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="Previewform.meta_title">
                            <span>
                              {{Previewform.meta_title ? Previewform.meta_title.length <= 60 ? 60 - Previewform.meta_title.length : 0 : 60 }}
                            </span> Remaining
                          </p>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="meta description" rules="max:500" v-slot="{ errors }">
                        <label for="category-meta-description">Meta Description</label>
                        <div class="tableFromFieldItem">
                          <textarea 
                            name="category-meta-description" 
                            id="category-meta-description" 
                            placeholder="Meta description" 
                            form="category-information"
                            v-model="Previewform.meta_description"
                            :disabled="tourIsActive"
                          >
                          </textarea>
                          <p class="fieldSeoMessage" v-if="Previewform.meta_description && Previewform.meta_description.length <= 300">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Do not exceed 300 characters when entering the category meta desscription for SEO
                          </p>
                          <p class="fieldSeoMessage" v-else-if="Previewform.meta_description && Previewform.meta_description.length > 300">
                            <span style="display: inline-flex;margin-right: 5px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                              </svg> 
                            </span>
                            Not SEO friendly
                          </p>
                          <p class="remainingTxt" v-if="Previewform.meta_description">
                            <span>
                              {{Previewform.meta_description ? Previewform.meta_description.length <= 300 ? 300 - Previewform.meta_description.length : 0 : 300 }}
                            </span> 
                            Remaining
                          </p>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="ctmFlexRow">
                      <div class="statusColumnBox">
                        <div class="tableFromFieldBox" id="category-active-switch">
                          <label>Status</label>
                          <div class="actionSwitch">
                            <label class="switch">
                              <input 
                                type="checkbox"
                                :value="Previewform.isActive"
                                v-model="Previewform.isActive"
                                :disabled="tourIsActive"
                              >
                              <span class="slider"></span>
                              <p class="ctmSwitchLabel">{{Previewform.isActive ? 'Active' : 'Disabled'}}</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="statusColumnBox">
                        <div class="tableFromFieldBox" id="category-indexed-switch">
                          <label>Indexed</label>
                          <div class="actionSwitch">
                            <label class="switch">
                              <input
                                type="checkbox"
                                :value="Previewform.isIndexed"
                                v-model="Previewform.isIndexed"
                                :disabled="tourIsActive"
                              >
                              <span class="slider"></span>
                              <p class="ctmSwitchLabel">{{Previewform.isIndexed ? 'Yes' : 'No'}}</p>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
            <!-- add/update category form ends here -->
              
          </div>
          <!-- data table ends here -->
          
          <!--bottom action bar starts here -->
          <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
            <div class="col-md-6 model-footer-main1">
              <div class="bottom-bar">
                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != categoryList.length">
                  <i class="fa fa-check-square-o" aria-hidden="true"></i>
                  <p>SELECT ALL</p>
                </a>
                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                  </svg>
                  <p>CLEAR SELECTION</p>
                </a>
              </div>
              <div class="model-footer-2">{{ checkedItems.length }}</div>
            </div>
            <div class="col-md-6 model-footer-main1 product-row-reverse">
              <div class="bottom-bar">
                <a href="" class="model-footer-1 mr-4" @click.prevent="applyAction('active')">
                  <p>ACTIVE</p>
                </a>
                <a href="" class="model-footer-1 mr-4" @click.prevent="applyAction('disable')">
                  <p>DISABLE</p>
                </a>
                <a href="" class="model-footer-1 ml-4" @click.prevent="applyAction('delete')">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                  <p>DELETE</p>
                </a>
              </div>
            </div>
          </div>
          <!--bottom action bar ends here -->
          
        </div>
      </div>
    </div>

    <v-tour name="productCategoryTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
    
  </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import Multiselect from "vue-multiselect";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
  metaInfo(){
    return {
      title: 'E-Commerce | Product Category | YeetCommerce',
    };
  },
  data()  {
    return {
      base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
      Previewform:{
        id: '',
        name: '',
        description: '',
        meta_title:'',
        meta_description: '',
        status: true,
        category_type: 'main',
        parent_id: null,
        parents: [],
        isActive: true,
        isIndexed: true,
        isTouched: false,
        created_by_user: 'admin',
        slug: '',
        addImage: null,
        addImage_src: '',
        image: null,
        image_src: '',
      },
      defaultPreviewForm:{
        id: '',
        name: '',
        description: '',
        meta_title:'',
        meta_description: '',
        status: true,
        category_type: 'main',
        parent_id: null,
        parents: [],
        isActive: true,
        isIndexed: true,
        isTouched: false,
        created_by_user: 'admin',
        slug: '',
        addImage: null,
        addImage_src: '',
        image: null,
        image_src: '',
      },
      tempCategory:{
        id: '',
        name: '',
        description: '',
        meta_title:'',
        meta_description: '',
        status: true,
        category_type: 'main',
        parent_id: null,
        parents: [],
        isActive: true,
        isIndexed: true,
        isTouched: false,
        created_by_user: 'admin',
        slug: '',
        addImage: null,
        addImage_src: '',
        image: null,
        image_src: '',
      },
      tempPreviewCategory:{
        id: '',
        name: '',
        description: '',
        meta_title:'',
        meta_description: '',
        status: true,
        category_type: 'main',
        parent_id: null,
        parents: [],
        isActive: true,
        isIndexed: true,
        isTouched: false,
        created_by_user: 'admin',
        slug: '',
        addImage: null,
        addImage_src: '',
        image: null,
        image_src: '',
      },
      imageValidations:{
        imgIsRequired: false,
        imgInvalidFormat: false,
        isNotImg: false,
      },
      selectedCategoryType: '',
      categorySave: false,
      selectedCategory: [],
      categoryList: [],
      parentCategory: [],
      parentCategoriesLoader: false,
      parentCategoriesTimer: undefined,
      selectedParent: {},
      isLoading: false,
      isLoadingBox: false,
      isMasterSearched: false,
      editorOptions:{
        placeholder: 'Description',
        theme: 'snow',
        modules:{
          toolbar:{
            container:[ 
              [{ "font": [] },],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              ["blockquote", "code-block"],
              ['bold', 'italic', 'underline', 'strike'],
              ["clean"],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{'color': []}, {'background': []}],
              [{ 'direction': 'rtl' }, { 'align': [] }],
            ],
          }
        }
      },
      checkedItems: [],
      deletingItems: [],
      deleteLoader: false,
      isCreateNew: false,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false,
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //search bar step
        {
          target: '#module-search',
          content: `<strong>Search Category</strong><br>It enables you to search specific categories.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        //form steps
        {
          target: '#preview-record',
          content: `<strong>Categories Table</strong><br>You can preview and edit the added categories by clicking on each individual record present in the table.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#add-new-record',
          content: `Add a <strong>Category</strong><br>By clicking this button, you can add a new category and open a form to input and save the details.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-details-preview',
          content: `<strong>Category Details Form</strong><br>This form can be used to add details about the category and save them.`,
          params: {
            highlight: false,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-name-field-1',
          content: `<strong>Category Name Field</strong><br>You can add the name of category using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-slug-field',
          content: `<strong>Category Slug Field</strong><br>Slug of category can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#category-type-field',
          content: `<strong>Category Type Selection</strong><br>You can select the type of category using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#parent-category-section',
          content: `<strong>Parent Category Selection</strong><br>If you are adding a sub category, parent category selection is a must.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-description-field',
          content: `<strong>Category Description</strong><br>category description can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-featured-image',
          content: `<strong>Category Featured Image</strong><br>It enables you to add featured image for the category`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-meta-title',
          content: `<strong>Category Meta Title</strong><br>Meta title for the category can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-meta-description',
          content: `<strong>Category Meta Description</strong><br>Meta description for the category can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#category-active-switch',
          content: `<strong>Category Status</strong><br>Category status can be changed between active and hide using this switch.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'right'
          }
        },
        {
          target: '#category-indexed-switch',
          content: `<strong>Category Indexed Status</strong><br>Category index status can be changed between yes and no using this switch.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#category-name-field-3',
          content: `<strong>Add Category Name</strong><br>You can add/update the name of category through this field as well.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'top'
          }
        },
        {
          target: '#category-name-field-2',
          content: `<strong>Category Name Field</strong><br>You can add the name of category using this field too.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'right'
          }
        },
        {
          target: '#expand-form',
          content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#closeSide',
          content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
        {
          target: '#save-category-btn',
          content: `<strong>Save Category Details</strong><br>Added category details can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'left'
          }
        },
      ],
      tourIsActive: false,
    }
  },
  components: {
    subHeader,
    Multiselect,
    moduleStats,
    tutorialsAction,
    loaderBtn,
    moduleSearch,
  },
  computed:{
    ...mapGetters({
      setting: 'settings_module/settings',
      categories: 'productCategories_module/categories',
      categoriesCurrentPage: 'productCategories_module/currentPage',
      categoriesLastPage: 'productCategories_module/lastPage',
      categorySearchKeyword:'productCategories_module/keyword',
      isPending: 'productCategories_module/isPending',
    }),
  },
  watch:{
    'Previewform':{
      handler:function(value){

        if((value.id == -1) && (value.name || value.slug  || value.description || value.image || value.addImage || value.meta_title 
        || value.meta_description )){

          value.isTouched = true;

        }else{

          value.isTouched = false;

        }

      },
      deep:true
    },
    'categories':{
      handler:function(val){
        
        this.isLoadingBox = false;

        if(this.Previewform.id == -1){
        
          this.categoryList = _.cloneDeep(val);
          
          this.categoryList.unshift(this.Previewform);

        }else if(this.tempPreviewCategory.id != ''){

          this.categoryList = _.cloneDeep(val);

          this.categoryList[this.Previewform.index] = this.Previewform;

        }else{

          this.categoryList = _.cloneDeep(val);

        }

        if(this.isCreateNew){

          this.createCategory();

          this.isCreateNew = false;

        }

        if(this.isMasterSearched){

          this.previewSearchedCategory(this.categoryList[0],0);

          this.isMasterSearched = false;
        }

      },
      deep:true
    },
  },
  methods: {
    handleScrollEvent(event){

      let element = this.$refs.categoriesTable;

      let maxScrollTop = element.scrollHeight - element.clientHeight;

      if(element.scrollTop >= maxScrollTop - 100){

        if(this.isPending == false && this.categoriesCurrentPage != this.categoriesLastPage){

          this.$store.dispatch('productCategories_module/fetchProductCategoriesRequest',{ size: 15, page: this.categoriesCurrentPage + 1, loadData:'more' });

        }
        
      }

    },
    slugGeneration(field){

      let slug = '';

      if(field == 'name'){

        slug = this.Previewform.name.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');
        slug = slug.replace(/-$/, "");

      }else if(field == 'slug'){

        slug = this.Previewform.slug.toLowerCase().trim();
        slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
        slug = slug.replace(/\s/g, '-');
        slug = slug.replace(/(\W)\W*/g, '$1');

      }

      this.Previewform.slug = slug;

    },
    generateUrl(category){

      if(category.image){

        if(typeof(category.image) == "string"){

          return this.base_image_url + this.setting.store_id + '/category/' + category.image;

        }else{

          return category.image_src;

        }

      }

    },
    categoryTypeChangeHandle(){

      if(this.selectedCategoryType == 'sub'){

        if(this.categoriesLastPage == 1){

          if(this.parentCategory.length != (this.categories.length - 1)){
          
            this.loadParentCategories(null);

          }

        }else if(this.parentCategory.length < 10){

          this.loadParentCategories(null);

        }

      }else if(this.selectedCategoryType == 'main'){

        this.Previewform.parent_id = null;

      }

    },
    categorySelection(category){

      this.tempCategory = _.cloneDeep(category);

    },
    async handleOTGUpdate(category,index){

      if(this.Previewform.id == ''){

        if(category.id != -1){

          if(this.tempCategory.name != category.name){

            if(!category.created_by_user){

              if(this.tempCategory.name != category.name){
                
                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "Default category can't be modified.",
                });

                this.categoryList[index].name = this.tempCategory.name;

              }

              return

            }

            if(category.name.trim().length == 0){
            
              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The category name field is required.",
              });

              this.categoryList[index].name = this.tempCategory.name;

              return

            }

            if(category.name.trim().length > 250){

              this.$notify({
                type: 'warning',
                title: 'Alert',
                message: "The category name may not be greater than 250 characters.",
              });

              this.categoryList[index].name = this.tempCategory.name;

              return

            }

            MessageBox.confirm(
              'You have unsaved changes, save and proceed?',
              'Confirm',
              {
                distinguishCancelAndClose: true,
                confirmButtonText: 'Save',
                cancelButtonText: 'Discard Changes',
              }
            ).then(async () => {

              let loader = Loading.service({
                text: "The category is being updated. Please wait!",
                fullscreen: true,
              });

              //update category
              let slug = category.name.toLowerCase();
              slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
              slug = slug.replace(/\s/g, '-');
              slug = slug.replace(/(\W)\W*/g, '$1');

              category.slug = slug;

              let formData = new FormData();
              let form = category;
              
              formData.append('id',form.id);
              formData.append('name', form.name.trim());
              formData.append('description', form.description.trim());

              if(form.image_src){
                formData.append('image', form.image);
              }

              if(form.meta_title){
                formData.append('meta_title', form.meta_title.trim());
              }
              if(form.meta_description){
                formData.append('meta_description', form.meta_description.trim());
              }

              formData.append('isActive', form.isActive == 1 ? 1 : 0);
              formData.append('isIndexed', form.isIndexed == 1 ? 1 : 0);
              
              if(form.parent_id == null){

                formData.append('type', 'main');

              }else{

                formData.append('type', 'sub');
                formData.append('parent_id',form.parent_id);

              }

              formData.append('slug', form.slug);

              try{
                let res = await this.$axios.post('/updateCategory', formData);
                if(res.data.request_status == 'successful'){

                  this.isLoading = false;

                  this.$notify({
                    type: 'success',
                    title: 'Success',
                    message: res.data.message,
                  });

                  let categoryIndex = this.categoryList.findIndex((category) => category.id == res.data.category.id)

                  let payload = {
                    index: categoryIndex,
                    category: res.data.category
                  }

                  this.$store.commit('productCategories_module/update_categories_list',payload);
                  
                  this.$store.commit('gallery_module/CLEAR_MODULE');

                  this.$store.commit('products_module/CLEAR_MODULE');

                }
              }catch(error){

                this.categoryList[index].name = this.tempCategory.name;
                this.categoryList[index].slug = this.tempCategory.slug;

                this.tempCategory = [];

                if(error.response){

                  if(error.response.data.error.name){

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.response.data.error.name[0],
                    });

                  }else if(error.response.data.error.slug){

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.response.data.error.slug[0],
                    });

                  }else if(error.response.data.error.id){

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.response.data.error.id[0],
                    });

                  }else if(error.response.data.error.includes('storage limit has been reached')){

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.response.data.error,
                    });

                  }else{

                    this.$message({
                      type: 'error',
                      showClose: true,
                      message: error.response.data.message,
                    });

                  }
                }else{

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.message,
                  });

                }

              }finally{

                loader.close();
                
              }

            }).catch(()=>{
              
              this.categoryList[index].name = this.tempCategory.name;
              this.categoryList[index].slug = this.tempCategory.slug;

              this.$notify({
                type: "info",
                title: "Changes Discarded",
                message: "Changes discarded successfully.",
              });

              this.tempCategory = [];
            
            });

          }

        }

      }else{

        if(category.id != -1){

          if(category.id != this.Previewform.id){

            if(this.tempCategory.name != category.name){

              if(!category.created_by_user){

                if(this.tempCategory.name != category.name){
                  
                  this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "Default category can't be modified.",
                  })

                  this.categoryList[index].name = this.tempCategory.name;
                }

                return

              }

              if(category.name.trim().length == 0){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The category name field is required.",
                })

                this.categoryList[index].name = this.tempCategory.name;

                return

              }

              if(category.name.trim().length > 250){

                this.$notify({
                  type: 'warning',
                  title: 'Alert',
                  message: "The category name may not be greater than 250 characters.",
                });

                this.categoryList[index].name = this.tempCategory.name;

                return

              }

              MessageBox.confirm(
                'You have unsaved changes, save and proceed?',
                'Confirm',
                {
                  distinguishCancelAndClose: true,
                  confirmButtonText: 'Save',
                  cancelButtonText: 'Discard Changes',
                }
              ).then(async () => {
                
                let loader = Loading.service({
                  text: "The category is being updated. Please wait!",
                  fullscreen: true,
                });

                //update category
                let slug = category.name.toLowerCase();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

                category.slug = slug;

                let formData = new FormData();
                let form = category;
                
                formData.append('id',form.id);
                formData.append('name', form.name.trim());
                formData.append('description', form.description.trim());
                if(form.image_src){
                  formData.append('image', form.image);
                }

                if(form.meta_title){
                  formData.append('meta_title', form.meta_title.trim());
                }
                if(form.meta_description){
                  formData.append('meta_description', form.meta_description.trim());
                }

                formData.append('isActive', form.isActive == 1 ? 1 : 0);
                formData.append('isIndexed', form.isIndexed == 1 ? 1 : 0);
                
                if(form.parent_id == null){
                  formData.append('type', 'main');
                }else{
                  formData.append('type', 'sub');
                  formData.append('parent_id',form.parent_id)
                }

                formData.append('slug', form.slug);

                try{
                  let res = await this.$axios.post('/updateCategory', formData);
                  if(res.data.request_status == 'successful'){
                    this.isLoading = false;

                    this.$notify({
                      type: 'success',
                      title: 'Success',
                      message: res.data.message,
                    });

                      let payload = {
                        index: this.Previewform.index,
                        category: res.data.category
                      }

                    this.$store.commit('productCategories_module/update_categories_list',payload);
                    
                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('products_module/CLEAR_MODULE');

                  }
                }catch(error){

                  this.categoryList[index].name = this.tempCategory.name;
                  this.categoryList[index].slug = this.tempCategory.slug;

                  this.tempCategory = [];

                  if(error.response){

                    if(error.response.data.error.name){

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.error.name[0],
                      });

                    }else if(error.response.data.error.slug){

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.error.slug[0],
                      });

                    }else if(error.response.data.error.id){

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.error.id[0],
                      });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.error,
                      });

                    }else{

                      this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.message,
                      });

                    }
                    
                  }else{

                    this.$message({
                      type: "error",
                      showClose: true,
                      message: error.message,
                    });

                  }

                }finally{

                  loader.close();

                }

              }).catch(()=>{
                
                this.categoryList[index].name = this.tempCategory.name;
                this.categoryList[index].slug = this.tempCategory.slug;

                this.$notify({
                  type: "info",
                  title: "Changes Discarded",
                  message: "Changes discarded successfully.",
                });

                this.tempCategory = [];
              
              });

            }

          }else{

            this.slugGeneration('name');

          }

        }else{

          this.slugGeneration('name');

        }

      }

    },
    previewCategory(listedCategory,i,field,e){

      if(field == 'input'){

        if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
        
          if(listedCategory.id != -1){

            if(this.Previewform.id == -1){
              
              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  type: "warning",
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                }
              ).then(()=>{

                let index = this.categoryList.findIndex((category) => category.id == -1);
                                
                if(index != -1){

                  this.categoryList.splice(index, 1);

                }

                if(!this.categorySave){

                  let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                  if(tempIndex != -1){

                    this.categoryList[tempIndex] = this.tempPreviewCategory;

                  }

                }

                let category = listedCategory;

                // this.parentCategory = this.categoryList.filter((x)=>{

                //   if(x.id != category.id && x.created_by_user){

                //     return x;

                //   }

                // });

                if(category.parents.length > 0){

                  this.selectedCategoryType = 'sub'
                  
                  this.selectedParent = category.parents[0]

                  let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

                  if(categoryIndex == -1){

                    this.loadParentCategories(null);

                  }

                }else{

                  this.selectedCategoryType = 'main'

                }

                this.$refs.addPreviewFileInput.value = ''

                this.resetImageValidations();

                this.Previewform = category;
                this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
                this.Previewform.index = i;
                this.Previewform.image_src = null;
                this.Previewform.image = category.image;
                this.selectedCategory = this.Previewform;
                this.selectedParent = category.parents[0];

                this.checkedItems = [];

                this.isLoading = false;

                this.tempPreviewCategory = _.cloneDeep(this.Previewform);

              }).catch((error)=>{});

            }else{

              let index = this.categoryList.findIndex((category) => category.id == -1);
                                
              if(index != -1){

                this.categoryList.splice(index, 1);

              }

              if(!this.categorySave && (this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id != listedCategory.id)){
                
                let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                if(tempIndex != -1){

                  this.categoryList[tempIndex] = this.tempPreviewCategory;

                }

              }

              if(this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id == listedCategory.id){

                let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                this.categoryList[tempIndex] = _.cloneDeep(this.categories[tempIndex]);

                let category = this.categoryList[tempIndex];

                // this.parentCategory = this.categoryList.filter((x)=>{

                //   if(x.id != category.id && x.created_by_user){

                //     return x;

                //   }

                // });

                if(category.parents.length > 0){

                  this.selectedCategoryType = 'sub'
                  
                  this.selectedParent = category.parents[0]

                  let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

                  if(categoryIndex == -1){

                    this.loadParentCategories(null);

                  }

                }else{

                  this.selectedCategoryType = 'main'

                }

                this.$refs.previewFileInput.value = ''

                this.resetImageValidations();

                this.Previewform = category;
                this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
                this.Previewform.index = i;
                this.Previewform.image_src = null;
                this.Previewform.image = category.image;
                this.selectedCategory = this.Previewform;
                this.selectedParent = category.parents[0];

                this.checkedItems = [];

                this.isLoading = false;

                this.tempPreviewCategory = _.cloneDeep(this.categoryList[tempIndex]);

              }else{

                let category = listedCategory;

                // this.parentCategory = this.categoryList.filter((x)=>{

                //   if(x.id != category.id && x.created_by_user){

                //     return x;

                //   }

                // });

                if(category.parents.length > 0){

                  this.selectedCategoryType = 'sub';
                  
                  this.selectedParent = category.parents[0];

                  let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

                  if(categoryIndex == -1){

                    this.loadParentCategories(null);

                  }

                }else{

                  this.selectedCategoryType = 'main';

                }

                this.$refs.previewFileInput.value = '';

                this.resetImageValidations();

                this.Previewform = category;
                this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
                this.Previewform.index = i;
                this.Previewform.image_src = null;
                this.Previewform.image = category.image;
                this.selectedCategory = this.Previewform;
                this.selectedParent = category.parents[0];

                this.checkedItems = [];

                this.isLoading = false;
                
                this.tempPreviewCategory = _.cloneDeep(category);

              }

            }

          }
        
        }

      }else{

        if(!this.tourIsActive){

          if(listedCategory.id != -1){
          
            if(this.Previewform.id == -1){
              
              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  type: "warning",
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                }
              ).then(()=>{

                let index = this.categoryList.findIndex((category) => category.id == -1);
                                
                if(index != -1){

                  this.categoryList.splice(index, 1);

                }

                if(!this.categorySave){

                  let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                  if(tempIndex != -1){

                    this.categoryList[tempIndex] = this.tempPreviewCategory;

                  }

                }

                let category = listedCategory;

                // this.parentCategory = this.categoryList.filter((x)=>{

                //   if(x.id != category.id && x.created_by_user){

                //     return x;

                //   }

                // });

                if(category.parents.length > 0){

                  this.selectedCategoryType = 'sub';
                  
                  this.selectedParent = category.parents[0];

                  let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

                  if(categoryIndex == -1){

                    this.loadParentCategories(null);

                  }

                }else{

                  this.selectedCategoryType = 'main';

                }

                this.$refs.addPreviewFileInput.value = '';

                this.resetImageValidations();

                this.Previewform = category;
                this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
                this.Previewform.index = i;
                this.Previewform.image_src = null;
                this.Previewform.image = category.image;
                this.selectedCategory = this.Previewform;

                this.checkedItems = [];

                this.isLoading = false;

                this.tempPreviewCategory = _.cloneDeep(this.Previewform);

              }).catch(()=>{})
            
            }else{

              let index = this.categoryList.findIndex((category) => category.id == -1);
                                
              if(index != -1){

                this.categoryList.splice(index, 1);

              }

              if(!this.categorySave && (this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id != listedCategory.id)){
                
                let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                if(tempIndex != -1){

                  this.categoryList[tempIndex] = this.tempPreviewCategory;

                }

              }

              if(this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id == listedCategory.id){

                let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                this.categoryList[tempIndex] = _.cloneDeep(this.categories[tempIndex]);

                let category = this.categoryList[tempIndex];

                this.parentCategory = this.categoryList.filter((x)=>{

                  if(x.id != category.id && x.created_by_user){

                    return x;

                  }

                });

                if(category.parents.length > 0){

                  this.selectedCategoryType = 'sub';
                  
                  this.selectedParent = category.parents[0];

                  let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

                  if(categoryIndex == -1){

                    this.loadParentCategories(null);

                  }

                }else{

                  this.selectedCategoryType = 'main';

                }

                this.$refs.previewFileInput.value = '';

                this.resetImageValidations();

                this.Previewform = category;
                this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
                this.Previewform.index = i;
                this.Previewform.image_src = null;
                this.Previewform.image = category.image;
                this.selectedCategory = this.Previewform;
                this.selectedParent = category.parents[0];

                this.checkedItems = [];

                this.isLoading = false;

                this.tempPreviewCategory = _.cloneDeep(this.categoryList[tempIndex]);

              }else{

                let category = listedCategory;

                // this.parentCategory = this.categoryList.filter((x)=>{

                //   if(x.id != category.id && x.created_by_user){

                //     return x;

                //   }

                // });

                if(category.parents.length > 0){
                  
                  this.selectedCategoryType = 'sub';
                  
                  this.selectedParent = category.parents[0];

                  let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

                  if(categoryIndex == -1){

                    this.loadParentCategories(null);

                  }

                }else{
                  
                  this.selectedCategoryType = 'main';

                }

                this.$refs.previewFileInput.value = '';

                this.resetImageValidations();

                this.Previewform = category;
                this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
                this.Previewform.index = i;
                this.Previewform.image_src = null;
                this.Previewform.image = category.image;
                this.selectedCategory = this.Previewform;

                this.checkedItems = [];

                this.isLoading = false;

                this.tempPreviewCategory = _.cloneDeep(this.Previewform);

              }

            }

          }

        }

      }

    },
    previewSearchedCategory(recordCategory,i){

      let index = this.categoryList.findIndex((category) => category.id == -1);
                                
      if(index != -1){

        this.categoryList.splice(index, 1);

      }

      if(!this.categorySave){

        let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

        if(tempIndex != -1){

          this.categoryList[tempIndex] = this.tempPreviewCategory;

        }

      }

      let category = recordCategory;
    
      // this.parentCategory = this.categoryList.filter((x)=>{

      //   if(x.id != category.id){

      //     return x;

      //   }

      // });

      // if(category.parent_id == null){

      //   category.category_type = 'main'

      // }else{

      //   category.category_type = 'sub'

      // }

      if(category.parents.length > 0){

        this.selectedCategoryType = 'sub';

        this.selectedParent = category.parents[0];

        let categoryIndex = this.parentCategory.findIndex((listedCategory) => listedCategory.id == category.parents[0].id)

        if(categoryIndex == -1){

          this.loadParentCategories(null);

        }

      }else{

        this.selectedCategoryType = 'main';

      }

      this.Previewform = category;
      this.Previewform.parent_id = category.parents.length != 0 ? category.parents[0] : null;
      this.Previewform.index = i;
      this.Previewform.image_src = null;
      this.Previewform.image = category.image;
      this.selectedCategory = this.Previewform;
      this.selectedParent = category.parents[0];

      this.checkedItems = [];

      this.resetImageValidations();

      this.isLoading = false;

      this.tempPreviewCategory = _.cloneDeep(this.Previewform);

    },
    closePreview(){

      if(this.Previewform.isTouched){

        MessageBox.confirm(
          "Do you want to close? Entered information will be discarded.", 
          "Warning", 
          {
            type: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
          }
        ).then(async () => {

          let index = this.categoryList.findIndex((category) => category.id == -1);
                                
          if(index != -1){

            this.categoryList.splice(index, 1);

          }

          if(!this.categorySave){

            let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

            if(tempIndex != -1){

              this.categoryList[tempIndex] = this.tempPreviewCategory;

            }

          }

          if(!this.isCreateNew){

            if(this.Previewform.id != -1){

              this.$refs.previewFileInput.value = '';

            }else{

              this.$refs.addPreviewFileInput.value = '';

            }

          }

          this.Previewform.id = '';

          // this.Previewform = _.cloneDeep(this.defaultPreviewForm);

          this.tempCategory = _.cloneDeep(this.defaultPreviewForm);

          this.tempPreviewCategory = _.cloneDeep(this.defaultPreviewForm);

          this.selectedParent = {};

          this.selectedCategoryType = '';

          this.categorySave = false;

          this.isMasterSearched = false;

          this.resetImageValidations();

        }).catch(()=>{})

      }else{

        let index = null;

        this.categoryList.forEach((category) => {

          if(category.id == -1){

            index = this.categoryList.indexOf(category);

          }

        });

        if(index != null){

          this.categoryList.splice(index, 1);

        }

        if(!this.categorySave){

          let tempIndex = this.categoryList.findIndex(x=>x.id == this.tempPreviewCategory.id);

          if(tempIndex != -1){

            this.categoryList[tempIndex] = this.tempPreviewCategory;

          }

        }

        if(!this.isCreateNew){

          if(this.Previewform.id != -1){

            this.$refs.previewFileInput.value = '';

          }else{

            this.$refs.addPreviewFileInput.value = '';

          }

        }

        this.Previewform.id = '';

        // this.Previewform = _.cloneDeep(this.defaultPreviewForm);

        this.tempCategory = _.cloneDeep(this.defaultPreviewForm);

        this.tempPreviewCategory = _.cloneDeep(this.defaultPreviewForm);

        this.selectedParent = {};

        this.selectedCategoryType = '';

        this.categorySave = false;

        this.isMasterSearched = false;

        this.resetImageValidations();

      }

    },
    resetPreview(){

      let index = this.categoryList.findIndex((category) => category.id == -1);
      
      if(index != -1){

        this.categoryList.splice(index, 1);

      }

      this.Previewform.id = '';

      // this.Previewform = _.cloneDeep(this.defaultPreviewForm);

      this.tempCategory = _.cloneDeep(this.defaultPreviewForm);

      this.tempPreviewCategory = _.cloneDeep(this.defaultPreviewForm);

      this.selectedParent = {};

      this.selectedCategoryType = '';

      this.resetImageValidations();

      if(this.$refs.addPreviewFileInput){

        this.$refs.addPreviewFileInput.value = '';

      }
      
    },
    expand(){

      this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

    },
    categoryKeyEvent(e){

      if(e.key == 'Escape'){

        if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.$refs.viewInputForm.classList.remove('fullWidthSlide');

        }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

          this.closePreview();

        }

      }

    },
    resetImageValidations(){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

    },
    async changeImage(e){
      
      this.imageValidates = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }
      
      if(e.target.files.length > 0){

        const file = e.target.files[0];

        if(file.name.length > 50){

          this.$notify({
              type: "error",
              title: "Alert",
              message: "Uploaded file name must not exceed 50 characters.",
          }); 

          this.imageValidations.imgIsRequired = false

          if(this.$refs.addPreviewFileInput){

            this.$refs.addPreviewFileInput.value = '';

          }

          return

        }

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.Previewform.addImage = file;
          this.Previewform.addImage_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

          if(this.$refs.addPreviewFileInput){

            this.$refs.addPreviewFileInput.value = '';

          }

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6)
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

          if(!this.Previewform.addImage){
            this.imageValidations.imgIsRequired = true;
          }
          
          // this.imageValidations.imgInvalidFormat = true

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

          if(!this.Previewform.addImage){
            this.imageValidations.imgIsRequired = true;
          }
          // this.imageValidations.isNotImg = true

        }

      }

    },
    async changeImagePreviewForm(e){

      this.imageValidates = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){

        const file = e.target.files[0];

        if(file.name.length > 50){

          this.$notify({
              type: "error",
              title: "Alert",
              message: "Uploaded file name must not exceed 50 characters.",
          }); 

          this.imageValidations.imgIsRequired = false

          if(this.$refs.previewFileInput){

            this.$refs.previewFileInput.value = '';

          }

          return

        }

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
        
          this.Previewform.image = file;
          this.Previewform.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

          if(this.$refs.previewFileInput){

            this.$refs.previewFileInput.value = '';

          }

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6)
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

          if(!this.Previewform.image){
            this.imageValidations.imgIsRequired = true;
          }
          
          // this.imageValidations.imgInvalidFormat = true

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

          if(!this.Previewform.image){
            this.imageValidations.imgIsRequired = true;
          }

          // this.imageValidations.isNotImg = true

        }

      }

    },
    async validate(){

      this.$refs.categoryFormObserver.validate().then(success => {

        if(!success){

          const errors = Object.entries(this.$refs.categoryFormObserver.errors)
          .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

          this.$refs.categoryFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });
          
          if(errors.length > 0){

            if(this.Previewform.id == -1 && !this.Previewform.addImage){

              this.imageValidations.imgIsRequired = true;

            }else if(this.Previewform.id != -1 && !this.Previewform.image){

              this.imageValidations.imgIsRequired = true;

            }

          }else{

            if(this.Previewform.id == -1 && !this.Previewform.addImage){

              this.imageValidations.imgIsRequired = true;

              document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

            }else if(this.Previewform.id != -1 && !this.Previewform.image){

              this.imageValidations.imgIsRequired = true;

              document.querySelector("#category-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }

        }else{

          if(this.Previewform.id == -1 && !this.Previewform.addImage){

            this.imageValidations.imgIsRequired = true;

            document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

          }else if(this.Previewform.id != -1 && !this.Previewform.image){

            this.imageValidations.imgIsRequired = true;

            document.querySelector("#category-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

          }else if(this.Previewform.id == -1){

            this.resetImageValidations();
            
            this.addCategoryHandle();
            
          }else{

            this.resetImageValidations();

            this.updateCategoryHandle();
            
          }
          
        }

      });

    },
    createCategory(){
      
      if(this.isLoadingBox){

        this.$notify({
          type: "info",
          title: "Please Wait",
          message: "Categories are being retrieved.",
        });

        return

      }

      if(this.isLoading){

        this.$notify({
          type: "info",
          title: "Please Wait",
          message: "A category is currently being processed.",
        });

        return

      }

      let index = this.categoryList.findIndex(x=>x.id == -1);

      if(index != -1){

        // this.$notify({
        //   type: "error",
        //   title: "Can't Add New Category",
        //   message: "you have an unsaved category in the list",
        // });
        
        return
        
      }

      this.closePreview();

      this.Previewform = _.cloneDeep(this.defaultPreviewForm)

      this.Previewform.id = -1

      // let parent = {
      //   name: 'Main'
      // }

      // this.Previewform.parents.push(parent);

      let tempCategory = this.Previewform;

      this.selectedCategoryType = 'main';

      this.categoryList.unshift(tempCategory);
      
      // this.parentCategory = this.categoryList.filter((x)=>{

      //   if(x.id != this.Previewform.id){

      //     return x;

      //   }

      // });

      this.checkedItems = []

      if(!this.isCreateNew){

        this.$refs.categoryFormObserver.reset();

      }

    },
    async addCategoryHandle(){

      if(!this.Previewform.addImage){

        this.$notify({
          type: 'error',
          title: 'Alert',
          message: "Category Image is required.",
        });

        this.imageValidations.imgIsRequired = true

        document.querySelector("#category-featured-image")
        .scrollIntoView({ behavior: 'smooth',block: "center" });

        return

      }

      let formData = new FormData();
      
      let form = this.Previewform;

      formData.append('name', form.name.trim());
      formData.append('description', form.description.trim());
      formData.append('type', form.category_type);
      formData.append('isActive', form.isActive ? 1 : 0);
      formData.append('isIndexed', form.isIndexed ? 1 : 0);
      formData.append('slug', form.slug.replace(/-$/, ""));

      if(this.selectedCategoryType == 'sub'){

        formData.append('parent_id', form.parent_id.id);

      }
      
      if(form.meta_title){

        formData.append('meta_title', form.meta_title);

      }

      if(form.meta_description){

        formData.append('meta_description', form.meta_description);

      }

      if(form.addImage){

        formData.append('image', form.addImage);

      }

      this.isLoading = true;
      try{
        let res = await this.$axios.post('/addCategory', formData);
        if(res.data.request_status == 'successful'){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.resetPreview();

          this.$store.commit('productCategories_module/add_categories_list',res.data.category);
          
          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.loadParentCategories(null);

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.error.slug){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.slug[0],
            });

          }else if(error.response.data.error.includes('hierarchy') || 
          error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.isLoading = false;

      }

    },
    async updateCategoryHandle(){
      
      if(this.Previewform.category_type == 'sub' && this.Previewform.parent_id == null){

        this.$notify({
          type: 'error',
          title: 'Alert',
          message: "Parent category is not selected.",
        });

        return

      }

      if(!this.Previewform.image && !this.Previewform.image_src){

        this.$notify({
          type: 'error',
          title: 'Alert',
          message: "Category Image is required.",
        });

        this.imageValidations.imgIsRequired = true;

        document.querySelector("#category-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

        return

      }

      let formData = new FormData();

      let form = this.Previewform;

      formData.append('id',form.id);
      formData.append('name', form.name.trim());
      formData.append('description', form.description.trim());
      formData.append('slug', form.slug.replace(/-$/, ""));
      formData.append('type', this.selectedCategoryType);
      formData.append('isActive', form.isActive ? 1 : 0);
      formData.append('isIndexed', form.isIndexed ? 1 : 0);

      if(form.image_src){

        formData.append('image', form.image);

      }

      if(form.meta_title){

        formData.append('meta_title', form.meta_title);

      }
      if(form.meta_description){

        formData.append('meta_description', form.meta_description);

      }
      
      if(this.selectedCategoryType == 'sub'){

        formData.append('parent_id', form.parent_id.id);

      }
      
      this.isLoading = true;
      try{
        let res = await this.$axios.post('/updateCategory', formData);
        if(res.data.request_status == 'successful'){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          let categoryIndex = this.categoryList.findIndex((category) => category.id == res.data.category.id)

          let payload = {
            index: categoryIndex,
            category: res.data.category
          }

          this.categoryList[categoryIndex] = res.data.category;

          this.$store.commit('productCategories_module/update_categories_list',payload);

          this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

          this.$store.commit('gallery_module/CLEAR_MODULE');

          this.$store.commit('products_module/CLEAR_MODULE');

          if(this.Previewform.id == res.data.category.id){

            this.categorySave = true;

            this.closePreview();

          }

          this.loadParentCategories(null);

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.error.slug){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.slug[0],
            });

          }else if(error.response.data.error.id){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.id[0],
            });
            
          }else if(error.response.data.error.includes('hierarchy') || 
          error.response.data.error.includes('hierarchical structure') || 
          error.response.data.error.includes('storage limit has been reached') ||
          error.response.data.error.includes('as its own parent')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.isLoading = false;

      }

    },
    async updateCategoryStatus(category,i){

      if(!this.tourIsActive){
        
        let loader = Loading.service({
          text: "The category is undergoing status update. Please wait!",
          fullscreen: true,
        });

        let formData = new FormData();

        formData.append('category_id', category.id);
        formData.append('status', category.isActive ? 0 : 1);

        category.isActive = !category.isActive

        try{
          let res = await this.$axios.post('/changeCategoryStatus',formData);
          if(res.data.status_code == "10051"){

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            })

            let payload = {
              index: i,
              updatedCategory: res.data.category,
            }

            this.$store.commit('productCategories_module/update_categories_status',payload);

            this.$store.commit('products_module/CLEAR_MODULE');

          }
        }catch(error){

          category.isActive = !category.isActive

          if(error.response){

            if(error.response.data.error.category_id){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error.category_id[0],
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

        }

      }

    },
    async deleteCategory(category,index){
      
      MessageBox.confirm(
        'Are you sure you want to delete the category?',
        'Warning',
        {
          type: 'warning',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        }
      ).then(async ()=>{

        if(!category.created_by_user){
            
          this.$notify({
            type: 'warning',
            title: 'Default Category',
            message: "Default category can't be removed.",
          });

          return 

        }
          
        let loader = Loading.service({
          text: "The category is being removed. Please wait!",
          fullscreen: true,
        });

        this.deletingItems.push(category.id);
        this.deleteLoader = true;

        try{
          let res = await this.$axios.post('/deleteCategory', {id: category.id});
          if(res.data.status_code == '1037'){

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });
            
            this.isLoadingBox = true;

            this.$store.dispatch('productCategories_module/fetchProductCategoriesRequest',{ size: 15 ,page: 1, loadData:'new' });

            this.$store.commit('products_module/CLEAR_MODULE');

            // this.$store.commit('productCategories_module/delete_categories_list',index);
            
            this.$store.commit('gallery_module/CLEAR_MODULE');

            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            this.$store.commit('products_module/CLEAR_MODULE');

            this.deleteLoader = false;
            this.deletingItems = [];

            //removing deleted item from checked items list
            this.checkedItems = this.checkedItems.filter((item) => {

              if(item != category.id){
                  
                return item;
                  
              }

            });

            this.loadParentCategories(null);

          }
        }catch(error){

          this.deleteLoader = false;
          this.deletingItems = [];

          if(error.response){

            if(error.response.data.error.id){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error.id[0],
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });
            
          }

        }finally{

          loader.close();

        }

      }).catch(()=>{})

    },
    async searchCategory(keyword){

      this.$refs.module_search.searchedKeyword = keyword

      this.$refs.module_search.search();

    },
    checkedItemHandle(categoryId){

      if(!this.tourIsActive && categoryId != -1 && !this.Previewform.id){

        let index = this.checkedItems.findIndex(item => item == categoryId);

        if(index != -1){

          this.checkedItems.splice(index,1)

        }else{

          this.checkedItems.push(categoryId)

        }

      }

    },
    toggleIsCheckedAll(){

      if(!this.tourIsActive && !this.Previewform.id){

        if((this.checkedItems.length == this.categoryList.length) ||
        (this.checkedItems.length == this.categoryList.length)){

          this.checkedItems = [];

        }else if(this.checkedItems.length < this.categoryList.length){

          this.checkedItems = [];
          
          this.categoryList.forEach(category => {

            if(category.id != -1){

              this.checkedItems.push(category.id);

            }

          });

        }

      }

    },
    clearSelection(){
      
      MessageBox.confirm(
        'Do you really want to uncheck the selected records?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async () => {

        this.checkedItems = [];

      }).catch(() => {})

    },
    applyAction(action){

      this.resetPreview();

      MessageBox.confirm(
        'Do you really want to make the changes?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async ()=>{

        if(action == "active" || action == "disable"){

          let loader = Loading.service({
            text: "The selected categories are undergoing status update. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append('resource_type','category');

          formData.append("resource_ids", JSON.stringify(this.checkedItems));
          formData.append("action", action);

          try{
            let res = await this.$axios.post("/bulk-action", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: 'success',
                title: 'Success',
                message: "Categories status has been successfully changed.",
              })

              let payload = {
                action: 'statusChange',
                status: action == "active" ? 1 : 0,
                listIds: this.checkedItems
              }

              this.$store.commit('productCategories_module/categories_bulk_action',payload);

              this.checkedItems = [];
            }
          }catch(error){

            this.deleteLoader = false;

            if(error.response){

              if(error.response.data.message){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: "One or more of the selected categories may have been deleted or didn't available.",
                });

              }

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }
          
        }

        if(action == "delete"){

          let loader = Loading.service({
            text: "The selected categories are being removed. Please wait!",
            fullscreen: true,
          });

          let formData = new FormData();

          formData.append('resource_type','category');

          formData.append("resource_ids", JSON.stringify(this.checkedItems));
          formData.append("action", action);

          this.deletingItems = _.cloneDeep(this.checkedItems);
          this.deleteLoader = true;

          try{
            let res = await this.$axios.post("/bulk-action", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: 'success',
                title: 'Success',
                message: "Categories have been successfully removed.",
              })

              // let payload = {
              //   action: 'delete',
              //   listIds: this.checkedItems
              // }

              this.isLoadingBox = true;

              this.$store.dispatch('productCategories_module/fetchProductCategoriesRequest',{ size: 15 ,page: 1, loadData:'new' });

              // this.$store.commit('productCategories_module/categories_bulk_action',payload);

              this.$store.commit('gallery_module/CLEAR_MODULE');

              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

              this.checkedItems = [];
              this.deletingItems = [];
              this.deleteLoader = false;

              this.loadParentCategories(null);

            }
          }catch(error){

            this.deletingItems = [];
            this.deleteLoader = false;

            if(error.response){

              if(error.response.data.message){

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: error.response.data.message,
                });

              }else{

                this.$message({
                  type: 'error',
                  showClose: true,
                  message: "One of the selected categories may have been deleted or isn't available.",
                });

              }

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }

        }

      }).catch(()=>{})

    },
    isDeletingItem(id){

      let find = this.deletingItems.find(x=>x == id);

      if(find){

        return true;

      }else{

        return false;

      }

    },

    livePreview(listedCategory){

      if(listedCategory.isActive){

        window.open('https://' + this.setting.domain + '/collections/' + listedCategory.slug);

      }else{

        this.$notify({
          type: 'info',
          title: 'Preview Unavailable',
          message: "To preview, ensure category is active.",
        });

      }

    },

    //load product categories method starts here
    customProductCategoryName({ name }){

      if(name){

        if(name.length > 15){

          let shortenedName = name.substr(0, 15);

          return `${shortenedName}...`;

        }else{

          return `${name}`;

        }

      }else{

        return ``;

      }

    },
    async loadParentCategories(query){

      if(query){

        clearTimeout(this.parentCategoriesTimer)

        this.parentCategoriesTimer = setTimeout(async () => {

          this.parentCategoriesLoader = true;
          try{
            let res = await this.$axios.get("getAllCategories",{
              params:{
                offset: 15,
                search: query ? query : null
              }
            });
            if(res.data.status_code == "1003"){

              if(this.Previewform.id != -1){

                this.parentCategory = res.data.categories.data.filter((x) => {

                  if(x.id != this.Previewform.id && x.created_by_user){
                    
                    return x;

                  }

                });

                if(this.Previewform.parents.length != 0){
                
                  let categoryIndex = this.parentCategory.findIndex((category) => category.id == this.Previewform.parents[0].id)

                  if(categoryIndex == -1){

                    this.parentCategory.unshift(this.Previewform.parents[0]);

                  }

                }

              }else{

                this.parentCategory = res.data.categories.data.filter((x) => {

                  if(x.created_by_user){

                    return x;

                  }

                });

              }

            }
          }catch(error){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            this.parentCategoriesLoader = false;

          }

        }, 500);

      }else{

        this.parentCategoriesLoader = true;
        try{
          let res = await this.$axios.get("getAllCategories",{
            params:{
              offset: 15,
              search: query ? query : null
            }
          });
          if(res.data.status_code == "1003"){

            if(this.Previewform.id != '' && this.Previewform.id != -1){
              
              this.parentCategory = res.data.categories.data.filter((x) => {

                if(x.id != this.Previewform.id && x.created_by_user){

                  return x;

                }

              });

              if(this.Previewform.parents.length != 0){

                let categoryIndex = this.parentCategory.findIndex((category) => category.id == this.Previewform.parents[0].id)

                if(categoryIndex == -1){

                  this.parentCategory.unshift(this.Previewform.parents[0]);

                }

              }

            }else{

              this.parentCategory = res.data.categories.data.filter((x) => {

                if(x.created_by_user){

                  return x;

                }

              });

            }

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message,
          });

        }finally{

          this.parentCategoriesLoader = false;

        }

      }

    },
    //load product categories method ends here

    //tour methods
    startCategoryTour(){

      this.$tours.productCategoryTour.start();

        this.tourIsActive = true;

      if(this.Previewform.id != ''){

        this.resetPreview();

      }

    },
    handleNextStep(currentStep){

      if(currentStep + 1 == 7){

        document.querySelector(".tableActionBtns").style.position = "static";

        document.querySelector(".productSlideBox").style.overflowY  = "hidden";

        this.createCategory();

      }

      if(currentStep + 1 == 12){

        this.Previewform.category_type = 'sub';

        this.selectedCategoryType = 'sub';

      }

      if(currentStep >= 18){

        document.querySelector(".tableActionBtns").style.position = "sticky";

      }

      if(currentStep + 1 == 21){

        if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.expand();

        }

      }

      if(currentStep + 1 == 22){

        this.expand();

      }

    },
    handlePreviousStep(currentStep){

      if(currentStep == 7){

        this.resetPreview();
        
        document.querySelector(".tableActionBtns").style.position = "sticky";

        document.querySelector(".productSlideBox").style.overflowY  = "auto";

      }

      if(currentStep == 12){

        this.Previewform.category_type = 'main';

        this.selectedCategoryType = 'main';

      }

      if(currentStep <= 19){

        document.querySelector(".tableActionBtns").style.position = "static";

      }

      if(currentStep == 21){

        if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.expand();

        }

      }

      if(currentStep == 22){

        if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

          this.expand();

        }

      }

    },
    handleTourStop(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.resetPreview();

      this.tourIsActive = false;

    },
    handleTourFinish(){

      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.resetPreview();

      this.tourIsActive = false;

    },
    handleTourSkip(){
      
      document.querySelector(".tableActionBtns").style.position = "sticky";

      document.querySelector(".productSlideBox").style.overflowY  = "auto";

      this.resetPreview();

      this.tourIsActive = false;

    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },

  },
  mounted(){

    this.$refs.categoriesTable.addEventListener('scroll', this.handleScrollEvent);

    window.addEventListener('keydown',this.categoryKeyEvent);

    window.addEventListener('beforeunload', (event) => {

      let index = null;

      this.categoryList.forEach((category) => {

        if(category.id == -1){

          index = this.categoryList.indexOf(category);

        }

      });

      if(index != null){

        event.returnValue = "Are you sure you want to leave? entered information will be lost";

      }

    });

    if(this.$route.query.search){
      
      this.$refs.module_search.searchedKeyword = this.$route.query.search

      if(this.$route.query.preview == 1){

        this.isMasterSearched = true

      }

      this.$router.replace({'query': null});

      this.$refs.module_search.search();

    }

    //add form top navigation scroll handle
    this.$refs.viewInputForm.addEventListener("scroll",(e)=>{

      let maxScrollTop = e.target.scrollHeight - e.target.clientHeight;

      if(e.target.scrollTop >= maxScrollTop){

        document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

          item.classList.remove("active");

        });

        if(!this.tourIsActive){

          document.querySelector('#category-seo-navbtn').classList.add('active');

        }

      }else{

        document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

          item.classList.remove("active");

        });

        if(!this.tourIsActive){

          document.querySelector('#category-detail-navbtn').classList.add('active');

        }
        
      }

    });

  },
  async beforeMount(){
    
    try{

      if((!this.categories || this.categories.length == 0) && this.categorySearchKeyword.length == 0){

        this.isLoadingBox = true;

        this.$store.dispatch('productCategories_module/fetchProductCategoriesRequest',{ size: 15 ,page: 1, loadData:'new' });

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

      }else{

        this.categoryList = _.cloneDeep(this.categories);
        this.isLoadingBox = false;

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

        if(this.isCreateNew){

          this.createCategory();

          this.isCreateNew = false;

        }

      }

    }catch(error){

      this.isLoadingBox = false;

      this.$message({
        type: 'error',
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }

  },
  beforeDestroy(){

    window.removeEventListener('keydown',this.categoryKeyEvent);

  },
  beforeRouteLeave(to, from, next) {

    let index = null;

    this.categoryList.forEach((category) => {

      if(category.id == -1){

        index = this.categoryList.indexOf(category);

      }

    });

    if(window.innerWidth <= 1024){

      document.body.classList.remove('activeIpad');
      document.body.classList.add('hideSideBar');

    }

    if((this.Previewform.id || index != null) && to.fullPath != '/login'){

      MessageBox.confirm(
        'You may have unsaved changes, Proceeding will discard them',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Process',
          cancelButtonText: 'Stay',
        }
      ).then(()=>{

        this.resetPreview();

        next();

      }).catch(()=>{
        
      })

    }else{

      next()

    }

  },
};

window.myFunction = function (event) {
  // reset all menu items
  document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
    item.classList.remove("active");
  });
  // mark as active selected menu item
  event.target.classList.add("active");
};
</script>
<style>
  .viewOrderBox tbody tr td {
    cursor: inherit !important;
  }
  .category-tab-img {
    height: 400px;
    width: 400px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .category-tab-img:hover {
    border-color: #409EFF;
  }
  .category-tab-img img{
    height: 100%;
    width: 100%;
  }
  .category-tab-img svg{
    position: absolute;
    width: 50px;
    top: 43%;
    left: 0;
    right: 0;
    margin: 0 auto;
    fill: #d9d9d9;
  }
  .category-tab-img:hover svg{
    fill: #409EFF;
  }
  .custom-form.create-section .ql-editor {
    color: #000 !important;
  }
  .ql-editor{
    height: 200px;
  }

    /* States Css Start Here */
  .ctmDashboardCardRow{
    display: flex;
    align-items: center;
    gap: 15.4px;
    flex-wrap: wrap;
  }
  .statesWrapBlock{
    width: 15.83%;
  }
  .statesWrapBlock p{
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .statesWrapBlock p span{
    display: inline-flex;
    align-items: center;
    margin-left: 5.5px;
  }
  .statesWrapBlock p span svg{
    display: inline-block;
    width: 8.511px;
    height: 9.726px;
    margin-right: 2.5px;
  }
  .statesWrapBlock p span svg:nth-last-child(1){
    margin-right: 0px;
  }
  .ctmDashboardCardItem{
    width: 100%;
    padding: 12px 17px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ctmDashboardCardItem.lightBlueCart{
    background-color: #1EC2C2;
  }
  .ctmDashboardCardItem.lightYellowCart{
    background-color: #E0E847;
  }
  .ctmDashboardCardItem.lightRedCart{
    background-color: #FD4D5D;
  }
  .orderStatesTxtBlock{
    width: 144px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .statesTxtField{
    text-align: center;
  }
  .statesTxtField h4{
    font-size: 30px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField p{
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.weekDetailTxt{
    opacity: 0.9;
  }
  .statesTxtField.weekDetailTxt h4{
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.weekDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .statesTxtField.monthlyDetailTxt{
    opacity: 0.8;
  }
  .statesTxtField.monthlyDetailTxt h4{
    font-size: 19px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }
  .statesTxtField.monthlyDetailTxt p{
    font-size: 8px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 400;
  }
  .cardTxtWrap h4{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
  }
  .cardTxtWrap h4 span{
    display: inline-block;
    margin-bottom: 0px !important;
    color: #15223D;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    top: -5px;
    left: 3px
  }
  .cardTxtWrap p{
    margin-bottom: 0px !important;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  .cardIconWrap{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: #fff;
    padding: 12px;
  }
  .lightBlueCart .cardIconWrap{
    background: #2CCBCB;
  }
  .lightYellowCart .cardIconWrap{
    background: #D4DC38;
  }
  .lightYellowCart .cardTxtWrap h4{
    color: #15223D;
  }
  .lightYellowCart .cardTxtWrap p{
    color: #15223D;
  }
  .lightRedCart .cardIconWrap{
    background: #EF3F4F;
  }
  .cardIconWrap img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* element ui select box css starts here */

  /* .is-focus .el-input__inner:focus{
    border-color: #E8E8E8 !important;
  }
  .el-select .el-input__inner:focus{
    border-color: #E8E8E8 !important;
  }
  .is-focus .el-input__prefix svg{
    fill: #E8E8E8;
  } */

  /* element ui select box css ends here */


</style>